
import { clientModule } from '@/modules/client/client.vuex-module';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'TimeWarnView',
	components: {},
})
export default class TimeWarnView extends Vue {
	@Prop({ default: '0' }) next!: string;
	get type(): string {
		switch (this.next) {
			case 'OrderTimeEdit':
				return 'Reschedule Policy';
			case 'OrderTimeCancel':
				return 'Cancellation Policy';
			default:
				return 'Cancellation Policy';
		}
	}
	get canEdit(): boolean {
		switch (this.next) {
			case 'OrderTimeEdit':
				return !!orderModule.openActivity?.activity.AllowSelfReschedule;
			case 'OrderTimeCancel':
				return !!orderModule.openActivity?.activity.AllowSelfCancellation;
			default:
				return false;
		}
	}
	get copy(): string {
		switch (this.next) {
			case 'OrderTimeEdit':
				return orderModule.openActivity?.activity.ReschedulePolicy ?? '';
			case 'OrderTimeCancel':
				return orderModule.openActivity?.activity.CancellationPolicy ?? '';
			default:
				return `This activity is part of a package and is unable to be cancelled online. Please call our staff at ${clientModule.location?.PublicPhoneNumber} for assistance!`;
		}
	}
	onNext() {
		if (this.canEdit) {
			this.$router.push({ name: this.next });
		} else {
			this.$router.go(-1);
		}
	}
}
